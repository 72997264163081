.Step1OrderDetails__padded-values {
    align-items: center;
    height: calc(1.5em + .75rem + 2px);
}

.Step1OrderDetails__quantity-constants {
    width: 0;
    white-space: nowrap;
}

.Step1OrderDetails__grand-total-heading {
    position: absolute;
    right: 100%;
    top: 0;
    white-space: nowrap;
    min-width: 100%;
    background: inherit;
    padding: inherit;
    font-weight: bold;
}