.Home {
	background-color: white;
}

.Home__hero {
	border-top: 0.5px solid white;
}

.Home__splash {
	display: flex;
	justify-content: center;
	background-color: var(--nin-color-red);
	height: 360px;
	width: 100%;
	overflow: hidden;
}

.Home__announcements_area {
	position: absolute;
	margin: auto;
	width: 100%;
	z-index: 100;
}

.Home__announcements_area .alert {
	opacity: 0;
	animation: fadedown 0.2s forwards ease-out;
	box-shadow: 0 2px 5px rgba(0,0,0,.3);
	animation-delay: .8s
}
.Home__announcements_area .alert:nth-child(1) { animation-delay: .2s }
.Home__announcements_area .alert:nth-child(2) { animation-delay: .4s }
.Home__announcements_area .alert:nth-child(3) { animation-delay: .6s }

.Home__requirements-list {
	font-size: 0.875rem;
	margin-left: 0;
	padding-left: 1em;
}

@keyframes fadedown {
	0% {
		opacity: 0;
		transform: rotateX(60deg);
	}
	100% {
		opacity: 1;
		transform: rotateX(0);
	}
}
