.maintenance-page {
	height: 100%;
	margin: 0;
	font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', Osaka,
		'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', 'Lato', sans-serif;
	color: #666666;
	word-break: normal;
}
.top-bkg {
	position: absolute;
	height: 220px;
	width: 100%;
	background-color: white;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.35);
	z-index: -1;
}
.maintenance-page-container {
	max-width: 960px;
	margin: 0 auto;
	padding: 100px 20px;
}
.nin-section {
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.35);
	border-radius: 5px;
	margin: 10px 0;
	background-color: white;
	height: 13em;
}
.nin-section-title {
	background-color: #f4f3f4;
	border-bottom: 1px solid #ccc7c9;
	border-radius: 5px 5px 0 0;
	display: flex;
	padding: 10px 1%;
	font-size: 1.125em;
	font-weight: 600;
	line-height: 1.125em;
	margin: 0;
}
.nin-section-content {
	padding: 1%;
}
.maintenance-page-toad {
	width: auto;
	float: left;
	margin: 1.5rem;
}
.maintenance-page-text {
	font-size: 1em;
	line-height: 1.5em;
	padding-bottom: 5px;
	padding-top: 1.5rem;
	padding-right: 1.5rem;
}
@media screen and (max-width: 420px) {
	.maintenance-page-toad {
		display: none;
	}
}
