.ApprovalQueue .tab-content {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.ApprovalQueue__tabs {
	margin-top: 0;
}

.ApprovalQueue__tabs > ul.nav.nav-tabs {
	margin-right: -15px;
	margin-left: -15px;
}
