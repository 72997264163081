.BaseModal__modal-title {
    padding: 0;
    clear: none !important;
}
.BaseModal__close-button {
    margin-right: auto;
}
.BaseModal__close-button:only-child {
    margin-right: 0;
}
