.PasswordPrompt__heading{
    padding-top: 1em;
    clear: both;
}

.PasswordPrompt__subheading{
    padding-bottom: 1em;
    clear: both;
}

.PasswordPrompt__requirements {
    list-style: none;
    padding: 0;
    width: 100%;
}
@media (min-width: 992px) {
    .PasswordPrompt__requirements {
        column-count: 2;
        column-width: 50%;
        column-gap: 0;
    }
}

.PasswordPrompt__requirement-item {
    display: block;
    font-size: 14px;
    padding-left: 24px;
}

.PasswordPrompt__requirement-item:before {
    display: inline-block;
    position: absolute;
    content: '\25CF';
    margin-left: -24px;
    color: var(--blue);
}

.PasswordPrompt__requirement-item--fulfilled {
    display: block;
    font-size: 14px;
    padding-left: 24px;
    opacity: 0.3;
}

.PasswordPrompt__requirement-item--fulfilled:before {
    display: inline-block;
    position: absolute;
    content: '\25CF';
    margin-left: -24px;
    opacity: 0.3;
}

.PasswordPrompt__tooltip-link,
.PasswordPrompt__requirement-item--fulfilled .PasswordPrompt__tooltip-link {
    color: inherit;
    text-decoration: underline dashed;
}
