.EventDetailsPanel__panel-list dt {
    padding-bottom: 0;
}

.EventDetailsPanel__panel-list ul {
    padding-left: 1.5em;
}

.EventDetailsPanel__panel-list dt,
.EventDetailsPanel__panel-list dd {
    float: none;
}
