.PaymentModal__disabled-section {
    pointer-events: none;
    background: var(--nin-color-ltgray3);
    margin: -1rem -1rem 1rem -1rem;
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
}

.PaymentModal__disabled-section > * {
    opacity: 0.6;
}
