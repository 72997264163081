.table-transfer-docs {
	width: 100%;
}

.table-transfer-docs > tr > td {
	padding: 8px;
}

.download-transfer-file {
	cursor: pointer;
}
