.page-orders-items .btn-container .btn {
	margin-left: 16px;
}

.page-orders-items .btn-container .btn:first-child {
	margin-left: 0;
}

.page-orders-items .order-create {
	margin-right: -15px;
	margin-left: -15px;
}

/* ProductsSelection & ProductsSelectionRow styles */
.page-orders-items td.request-version {
	width: 10%; /* wider than col-*-1 (8.333%), narrower than col-*-2 (16.667%) */
}

.page-orders-items .request-part-sku {
	margin-top: 7px;
}

.page-orders-items div.total {
	float: right;
}

.page-orders-items .row-total div.total {
	margin: 0;
}

.page-orders-items .remove-row {
	display: block;
	cursor: pointer;
	margin-top: -1px;
	color: #d43f3a; /* from btn-danger */
}

.page-orders-items .remove-row:hover {
	color: #ac2925; /* from btn-danger:hover */
}

.page-orders-items .line-error .alert {
	padding: 5px 15px;
}

.loading-product-total-price {
	margin-left: 80%;
}

/* ORDER REVIEW*/

.order-review-dl > dt {
	text-align: left;
	margin-left: 20px;
}

.order-details-totals {
	background-color: #f1f1f1;
}
