.ProductDetailsPanel__panel-list dt {
    padding-bottom: 0;
}

.ProductDetailsPanel__panel-list ul {
    padding-left: 1.5em;
}

.ProductDetailsPanel__panel-list dt,
.ProductDetailsPanel__panel-list dd {
    float: none;
}
