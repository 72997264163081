.FilterBar__criteria-list {
    align-items: center;
	margin: 0 0 .5rem;
}

.FilterBar__criteria-list > * {
	margin-top: 2px;
	margin-bottom: 2px;
}

.FilterBar__criteria-list .dropdown-menu {
	font-size: 0.875rem;
	max-height: 20rem;
    background-clip: border-box;
}

.FilterBar__criteria-list .SearchableTextBox {
	margin-left: 15px;
}

.FilterBar__criteria-list .SearchableTextBox input[type=text] {
	font-size: 0.875rem;
}

.FilterBar__criteria-list .FilterBar__reset-button {
	font-size: 0.875rem;
	margin-left: 15px;
}

.FilterBar__criteria-list .FilterBar__reset-button .btn {
	margin: 2px 0;
}


.FilterBar__criteria-list .FilterBar__reset-button .btn[disabled] {
	border-width: 1px!important;
}
