
.MeatballDropdown {
    display: inline-block;
    border-radius: 4px;
}

.MeatballDropdown.show .MeatballDropdown__toggle {
    background-color: var(--nin-color-ltgray2);
}

.MeatballDropdown__toggle:after {
    content: none;
}

.MeatballDropdown .dropdown-header {
    text-transform: uppercase;
    font-size: .75rem;
    padding: .25rem 1.5rem 0;
    font-weight: 500;

    color: var(--nin-color-dkgray3);
}
