/*

This file is only for classes that have already been established in Bootstrap, Normalize/Reboot, or NOA's
uxstyles.

If you need to create a new site-wide class, please attach the styles to the component it belongs too. If
styles are for several components, consider creating a new component just for those styles and using that
new component in your existing code. Components are wonderful building blocks for webapps and they were
meant to hold code and styles just the same.

*/

body {
  font-size: 1rem;
  background-color: var(--nin-color-ltgray3);
  overflow-y: scroll;
}

h1, .h1 {
  font-size: 2.5rem;
  font-weight: 400;
}
h2, .h2 {
  font-size: 1.75rem;
  font-weight: 300;
}
h3, .h3 {
  font-size: 1.5rem;
  font-weight: 700;
}
h4, .h4 {
  font-size: 1.125rem;
  font-weight: 700;
}
h5, .h5 {
  font-size: 1em;
  font-weight: 700;
}
h6, .h6 {
  font-size: .875rem;
  font-weight: 400;
}

.table td, .table th {
  border-top: 0;
}

.navbar-red .nin-logo {
  background-size: 6.25rem;
  padding-left: 7.5rem;
  padding-right: .5rem;
}

.navbar-red .nav-link {
  text-decoration: none;
}
.navbar-red .nav-link:hover {
  opacity: 0.8;
}

td.action {
  text-align: right;
}
td.action a.remove {
  margin: .5em;
  display: inline-block;
}
.btn-container {
  margin: 100px 0 1em;
  border-top: 2px solid #E1e1e1;
  padding: 1.5em 0;
}

dl {
  overflow: auto;
  margin-bottom: 0;
}
dl dt {
  clear: left;
  text-align: left;
  width: 200px;
  padding-right: 1em;
  font-weight: 700;
}
dl.striped {
    margin-left: 0;
    margin-right: 0;
}
dl.striped dt:nth-child(4n+1), dl.striped dd:nth-child(4n+2) {
    background-color: var(--nin-color-ltgray3);
}
dl.striped dd, dl.striped dt {
    margin-bottom: 0;
    padding: .5em 1em;
    word-break: break-word;
}
dd {
  font-weight: 400;
}
/*
.status-completed {
  color: var(--nin-color-success);
  font-weight: bold;
}
.status-progress {
  color: var(--nin-color-warning);
  font-weight: bold;
}
*/
span.notifications {
  display: inline-block;
  margin: 0 .5em;
  background-color: #FFF;
  color: var(--nin-color-red);
  padding: 0 1em;
  border-radius: 10px;
}
span.new {
  display: inline-block;
  margin-right: 3px;
  background-color: yellow;
  color: var(--nin-color-red);
  padding: 0 1em;
  border-radius: 10px;
  font-size: 12px;
  border: 1px solid #999;
  text-transform: uppercase;
}
td.edited span {
  font-size: 12px;
}
textarea {
  resize: none;
}

.breadcrumb {
  list-style: none;
  margin: 0 0 1rem;
  padding: 0;
  font-size: 0.875rem;
  background: transparent;
}
.breadcrumb-item+.breadcrumb-item::before {
  content: "\003e";
}

.dropdown-menu {
  min-width: 12.5rem;
}
.dropdown-menu label {
  margin-left: .75rem;
  margin-right: .75rem;
}
.dropdown-menu label input {
  margin-right: .5em;
}
.dropdown-toggle {
  cursor: pointer;
}

.form-horizontal .control-label {
  padding-top: 5px;
}

.form-horizontal .control-value {
  padding-top: 5px;
}

.modal-70w {
  width: 50vw;    /* Occupy the 50% of the screen width */
  max-width: 50vw;
}

.page-orders-items .total {
  margin: 7px 0 0 4px;
  font-weight: 700;
}
.page-orders-items .request-date {
  position: relative;
}

.no-outline,
.no-outline:focus,
.no-outline:active:focus {
    outline: none;
}

.no-pointer {
    cursor: default !important;
}

.comment-container {
  margin: 1.5rem 0;
  padding: .75rem 0 1.5rem;
  border-bottom: 1px solid #CCC;
}
.comment-container p {
	margin: 0 0 1em;
	line-height: 1.4285em;
}
.comment-container .comment {
  padding: 1em 1em 0;
  word-wrap: break-word;
}
.comment-container .comment > p {
	white-space: pre-line;
}


.alert-success {
  border-color: var(--nin-color-success);
  border-left-width: 6px;
}

.toast {
  border-radius: 0;
  background-color: rgba(255,255,255,.96);
}

.toast .close {
  font-size: 1.25rem;
  font-weight: 400;
}

.toast-info {
  border-color: #2576bc;
  border-left-width: 4px;
}

.alert-icon::before  {
  font-family: "Font Awesome 5 Pro", "Icons";
}

.btn + .btn,
.btn-group + .btn,
.btn + .btn-group,
.btn-group + .btn-group {
  margin-left: .375rem;
}

.btn.btn-link:disabled,
.btn.btn-link.disabled {
  background-color: transparent!important;
  border-color: transparent!important;
}

.btn.btn-plain {
  padding: 0;
  vertical-align: initial;
}

.btn.btn-plain:disabled,
.btn.btn-plain.disabled {
  
  background-color: inherit!important;
  border-color: transparent!important
}

.text-underline {
  text-decoration: underline!important;
}

.dropdown-menu {
  z-index: 10001;
}
