.FilesUploadedPanel__asset-details {
	padding-left: 0;
	margin: 0;
}
.FilesUploadedPanel__asset-details button {
	line-height: 1
}

.FilesUploadedPanel__asset-item {
	display: block;
	position: relative;
	padding: 6px 0;
}
.FilesUploadedPanel__asset-item:first-child {
	padding-top: 0;
}
.FilesUploadedPanel__asset-item:last-child {
	padding-bottom: 0;
}

.FilesUploadedPanel__asset-item-interior {
    white-space: nowrap;
    overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
}

.FilesUploadedPanel__asset-item + .FilesUploadedPanel__asset-item  {
	border-top: 1px solid var(--nin-color-ltgray1);
}

.FilesUploadedPanel__asset-item-actions {
	display: inline-block;
	vertical-align: top;
}

.FilesUploadedPanel__asset-details .dropdown.btn-group button {
	border: none;
	background-color: transparent;
	box-shadow: none;
	padding: 0 8px 0 8px;
}

.FilesUploadedPanel__asset-details .dropdown.btn-group .caret {
	display: none;
}

.FilesUploadedPanel__asset-details .dropdown-menu {
	left: auto;
	right: 0;
}

.FilesUploadedPanel__more-assets-button {
	padding: 0;
}
