.Loading {
	width: 100%;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	height: 100%;
	z-index: 999;
	display: flex;
	flex-direction: column;
    flex-grow: 1;
    justify-content: center;
}

.Loading > * {
	margin: 0 auto;
	width: 4rem;
	height: 4rem;
	border-radius: 1rem;
}

.Loading--slim {
	display: block;
	position: static;
}

.Page__content .Loading {
    padding: 24vh 0;
	display: block;
	height: auto;
}

.Page__content .Loading.Loading--slim {
	padding: 0;
}

.modal-body .Loading {
    padding: 20% 0;
	display: block;
}

.Page__content .Loading > *,
.modal-body .Loading > * {
	width: 4rem;
	height: 4rem;
}

.load-div-modal {
	width: 100%;
}

.table-loader {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(220, 220, 220, 0.5);
	z-index: 999;
}

.LoadingText__inline {
    display: inline-block;
    margin: auto .75em;
}

.LoadingText__block {
	width: 20px;
	height: 20px;
}
.LoadingText__block .spinner-border-custom {
	width: 1.25rem;
	height: 1.25rem;
    border-width: .2em
}
