/*Override Bootstrap's default checkbox padding.*/
.sku-form > .form-group > .col-sm-5 > .checkbox {
	padding-top: 0;
}

.makercode-form > .form-group > .col-sm-5 > .form-control {
	padding-top: 0;
}

.company-profile-comment {
	white-space: pre-wrap;
}
