.DropdownSelect {
    min-width: 4rem;
}
.DropdownSelect .DropdownSelect__indicator {
    padding: 6px;
}
.DropdownSelect .DropdownSelect__value-container {
    padding: 0.125rem 0.75rem;
}
.DropdownSelect .DropdownSelect__control {
    /* derived from .form-control in bootstrap 4 */
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;

    min-height: calc(1.5em + 0.75rem + 2px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;

    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.DropdownSelect .DropdownSelect__single-value {
    /* Note: this takes care of a browser-specific issue in Firefox which causes the dropdown 
       control to expand outward if the current value is too large. Apparently, this is because 
       this element is set to grid-column-end: 3 by default. This does not affect Chrome because 
       it believes grid-column-end should has no effect on elements with display: block, and
       states so in the styles panel in devtools. This should be checked upon occasionally to see
       if the browsers will agree on how this css rule should be handled. */
    display: block;
    grid-column-end: auto;
}

.DropdownSelect .DropdownSelect__multi-value {
    border-radius: 0.25rem;
}

.DropdownSelect .DropdownSelect__multi-value__label {
    font-size: 0.875rem;
}

.DropdownSelect .DropdownSelect__multi-value__remove {
    padding-left: 2px;
    padding-right: 2px;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-right: 4px;
}

.DropdownSelect .DropdownSelect__multi-value__remove:hover {
    background-color: var(--red);
    color: white;
}

.DropdownSelect .DropdownSelect__control--is-disabled{
    /* derived from .form-control in bootstrap 4 */
    background-color: #e9ecef;
    opacity: 1;
}
    
.DropdownSelect .DropdownSelect__control--is-focused {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.DropdownSelect .DropdownSelect__menu {
    font-size: 14px;
    z-index: 999;
}

.DropdownSelect .DropdownSelect__option {
    padding: 6px 18px;
}
