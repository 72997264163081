.AutoComplete {
	position: relative;
}

/* .AutoComplete .caret {
    position: absolute;
    z-index: 2;
    top: 16px;
    right: 15px;
    pointer-events: none;
  } */

.no-suggestions {
	color: #999;
	padding: 0.5rem;
}

.suggestions {
	background: white;
	z-index: 999999;
	border: 1px solid #cccccc;
	border-top-width: 0;
	list-style: none;
	margin-top: 0;
	max-height: 165px;
	overflow-y: auto;
	padding-left: 0;
	padding-right: 0;
	width: 100%;
	position: absolute;
}

.suggestions li {
	padding: 0.5rem;
}

.suggestions li:hover {
	background-color: #337ab7;
	color: #fff;
	cursor: pointer;
	font-weight: 700;
}
