.DatePicker {
    position: relative;
}

.DatePicker__input {
    padding-right: 2.5em;
    width: 100%;
}

.DatePicker__date-glyph {
    position: absolute;
    top: .667rem;
    right: .333rem;
}

/* these rules are to supplement the npm module's original
stylesheet */

.react-datepicker {
    display: flex;
  }

.react-datepicker__month-container,
.react-datepicker__time-container {
    float: none;
}
