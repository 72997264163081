.approve-asset-modal-comment {
	padding-left: 2.4em;
	font-size: 14px;
	margin-bottom: 2.24em;
	color: #737373;
}

.approve-asset-modal .form-group {
	margin-bottom: 15px;
}

.asset-revision-comment {
	word-wrap: break-word;
	white-space: pre-line;
}

.checkbox {
	margin-top: 0;
	margin-bottom: 0;
}
