.address-info span {
	display: block;
}

.packout-removal:hover {
	text-decoration: underline;
	cursor: pointer;
}

.facility-activation-pointer > div > ul {
	text-align: left;
	min-width: 20px !important;
	padding: 5px 0 !important;
}

.managepackoutfacility-dialog div.checkbox input[type='checkbox'] {
	cursor: pointer;
}

