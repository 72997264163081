.PropertyDisplay {
    margin: 0;
}
.PropertyDisplay:not(.PropertyDisplay--compact) {
    padding-top: .25rem ;
    padding-bottom: .25rem ;
}

.PropertyDisplay:not(.PropertyDisplay--no-stripes,.PropertyDisplay--compact):nth-child(even) {
    background-color: var(--nin-color-ltgray3);
}

.PropertyDisplay--compact dt,
.PropertyDisplay--compact dd {
    display: block;
    width: 100%;
}
