.EventCalendar__calendar-container {
    min-height: 54rem;
}

.EventCalendar__legend {
    list-style: none;
}

.EventCalendar__calendar-container .rbc-month-row {
    min-height: 10.75rem;
}

.EventCalendar__popup {
    list-style: none;
}
