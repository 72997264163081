.Forms__text-input {
    resize: none;
}

.Forms__select:invalid {
    color: #777;
}

.Forms__control_loading {
    position: absolute;
    margin: 0 12px;
    top: 50%;
    margin-top: -10px;
}

.Forms__preface {
    padding-top: calc(0.375rem + 1px);
}
