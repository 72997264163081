.FAIcon {
    width: 1.18em;
    text-align: center;
    flex-shrink: 0;
}

@supports (font-family: "Font Awesome Pro 5") {
    .FAIcon * {
        display: none;
    }
    .FAIcon:before {
        display: inherit;
    }
}
