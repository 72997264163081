.pri-hide {
	visibility: hidden;
}

.lotcheck-queue-pointer {
	cursor: pointer;
}

/* .lotcheck-pri-formgroup {
	margin-left: 30px !important;
} */
/*
.holdCheckBox {
	margin-top: 23px !important;
} */

/* Entire row has cursor:pointer. User needs affirmation they are hovering ellipsis menu other than cursor */
#dropdown-basic-lotcheck:hover {
	font-weight: bolder;
	font-size: 20px;
}
