.status-mini {
    color: #FFF;
    font-size: .75rem;
    padding: .24rem .5rem;
    border-radius: .25rem;
    font-weight: 700;
    text-transform: uppercase;
}
.status-mini.status-rejected,
.status-mini.status-danger {
    background: #d04437;
}
.status-mini.status-pending,
.status-mini.status-info {
    background: #31708f;
  }
.status-mini.status-approved,
.status-mini.status-success {
    background: #3c763d;
}
.status-mini.status-warning {
    background: #fcf8e3;
    color:#8a6d3b;
}
.status-mini.status-secondary,
.status-mini.status-muted {
    background: #dadada;
    color: #000;
}
