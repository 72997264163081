.dropdown-menu-header {
    padding: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #CCC;
}

.dropdown-menu-header-link {
    display: inline-block;
    color: #337ab7;
    cursor: pointer;
}

.dropdown-menu-header-link.red {
    color: #E60012;
}

.DropdownFilter__dropdown {
    font-size: .875rem;
    padding: 7px 15px;
    border-radius: 4px;
    user-select: none;

    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.DropdownFilter__dropdown--open {
    background-color: var(--nin-color-ltgray2);
}

.DropdownFilter__dropdown--open > a {
    color: #0056b3;
    text-decoration: underline;
}

.DropdownFilter__columns {
    columns: 2;
    break-inside: avoid-column;
}

.DropdownFilter__scrollable {
	overflow-y: auto;
	overflow-x: hidden;
	min-width: 12.5rem;
	width: auto;
}

.DropdownFilter__checkbox {
	display: block;
    white-space: nowrap;
}
