.AddAssetModal__template-select {
    margin: 1em 0;
}

.AddAssetModal__table {
    margin-top: 1em;
}

.AddAssetModal__table > tbody > tr > td,
.AddAssetModal__table > tbody > tr > th {
    padding: 20px 8px;
    vertical-align: middle;
}

.AddAssetModal__table .help-block {
    position: absolute;
}