.Page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.Page__main-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.Page__width-limit {
    max-width: 1400px;
}

.Page__split-page {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.Page__content {
    display: block;
    background-color: #FFF;
    padding: 2em 1.25em;
    border: 1px solid #E1E1E1;
    flex-grow: 1;
    min-height: 28rem;
    overflow: hidden;
}

.nav-tabs + .tab-content .Page__content {
    border-top: 0;
}

.Page__fill-space,
.Page__fill-space > .tab-content,
.Page__fill-space > .tab-content > .tab-pane.active,
.Page__fill-space > *:only-child:not(.alert, .Page__content, table, h1, h2, h3, h4, h5, h6) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.Page__fill-space > .btn-group,
.Page__fill-space > .btn {
    align-self: start;
}

.tab-content>.active.Page__fill-space {
    display: flex;
}
