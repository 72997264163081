/* Create Product Modal*/
.createProductModal {
	width: 800px !important;
}

.addProductCode {
	margin-top: 0.24em;
	border: none;
	background: none;
	color: #538ec1;
}

.datepicker-control {
	list-style: none;
	width: 100%;
}

.datepicker-control > li {
	display: inline;
}

.date-glyph {
	line-height: 27px !important;
}

.makercode-form > .form-group > .col-sm-5 > .form-control {
	padding-top: 0;
}
