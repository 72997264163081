.product-profile-ellipses {
	margin-top: -25px !important;
}


/* Product Summary Tab */
.heading {
	text-align: right;
}

.table-distribution {
	width: 100%;
}

/* Assets Tab */

.asset-title-button {
	margin-top: -15px;
}

.asset-image {
	width: 150px;
	max-width: 200px;
}

/* Projections Tab */
.projection-year-col {
	background-color: #f3f3f3 !important;
	font-weight: bolder;
}

.gameCodeForm > .form-group > .col-sm-8 > .form-control {
	margin-top: 0;
}

/* Transfer Ownership Modal*/
.TransferOwnershipModal .modal-body {
	overflow-y: unset;
}

.digital-codes-note {
	margin-top: 40px;
}

.ProductProfile__asset-table {
	table-layout: fixed;
	width: 100%;
}
.ProductProfile__asset-table td {
	word-break: break-word;
}
.ProductProfile__asset-table ul {
	padding-left: 17px;
}
