.WSPRequestView__acknowledge-checkbox {
    background-color: #f2dede;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: var(--nin-color-dkred)!important;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.WSPRequestView__acknowledge-checkbox .form-group {
     margin-bottom: 0;
}

.WSPRequestView__acknowledge-checkbox--disabled {
    border-color: #ced4da;
    background-color: #e9ecef;
}
