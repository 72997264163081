.PhysicalOrderChecklistModal__table {
    margin: auto;
    width: auto;
    min-width:80%;
    border: 1px solid var(--nin-color-ltgray2);
    margin-bottom:1rem!important;
}

.PhysicalOrderChecklistModal__table tr:not(:last-child) {
    border-bottom: 1px solid var(--nin-color-ltgray2);
}

.PhysicalOrderChecklistModal__table td:first-child {
    text-align: left;
    width: 82%;
}
.PhysicalOrderChecklistModal__table td {
    text-align: right;
}



