.Step1OrderAndProducts__total-cell {
    text-align: right;
    position: relative;
}

.Step1OrderAndProducts__total-heading {
    display: flex;
    position: absolute;

    justify-content: flex-end;

    right: 100%;
    top: 0;
    white-space: nowrap;
    min-width: 100%;
    background: inherit;
    padding: inherit;
    font-weight: bold;
    text-align: right;
}

.Step1OrderAndProducts__padded-cell {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: calc(1.5em + .75rem + 2px);
}

.Step1OrderAndProducts__table {
    border-bottom: none;
}
