.ActionLink {
    display: inline;
    position: static;
    color: #337ab7;
    text-decoration: underline;
    margin: 0;
    padding: 0;
    border-width: 0;
    background: none;
    line-height: inherit;
    text-align: left;
}

.ActionLink:hover {
    cursor: pointer;
    color: #23527c;
    text-decoration: underline;
}

.ActionLink--dotted {
    display: inline;
    color: #337ab7;
    text-decoration: underline dashed;
    background: none;
    border: 0;
}

.ActionLink--dotted:hover {
    cursor: pointer;
    color: #23527c;
    text-decoration: underline dashed;
}
