.iframe-virtual-viewer {
	width: 100%;
	height: 100%;
}

.div-virtual-viewer {
	width: 100%;
	height: 100%;
}

.popupbox-content {
	height: 90%;
}

.div-virtual-viewer-content {
	height: 100%;
}
