.DropdownDateFilter__dropdown-menu,
.DropdownDateFilter__dropdown-menu.dropdown-menu {
    overflow-x: visible;
    overflow-y: visible;
}

.DropdownDateFilter__input {
    width: 12.5rem;
    padding-right: 2rem;
}

.DropdownDateFilter__dropdown-menu-contents {
    position: relative;
    margin: 12px;
}

.DropdownDatefilter__heading {
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
}


.DropdownDatefilter__icon,
i.DropdownDatefilter__icon.icon {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0.375rem;
    margin: 0 0.5rem;
}
