.BaseTable:not(.BaseTable--allow-overflow) {
	margin-right: -1.25rem;
    padding-right: 1.25rem;
}

.BaseTable:not(.BaseTable--allow-overflow) .ReactTable {
	flex-grow: 0
}

.BaseTable .ReactTable,
.BaseTable.Page__fill-space .ReactTable {
	background-color: #fff;
	border: 0 !important;
}

.BaseTable .ReactTable .rt-thead.-header {
	border: none;
	box-shadow: none;
}

.BaseTable .ReactTable .rt-thead .rt-th {
	border-bottom: 2px solid #3aa4ff;
	padding: 8px;
	line-height: 1.42857143;
	font-size: 1rem;
	font-weight: 700;
	border-right: none;
}



.BaseTable .ReactTable .rt-thead .rt-tr {
	text-align: left;
}



.BaseTable .ReactTable .rt-tbody .rt-td {
	padding: 8px;
	line-height: 1.42857143;
	font-size: 1rem;
	border-bottom: 1px solid #ddd;
	border-right: none;
}

.BaseTable .ReactTable .rt-tbody .rt-td ul:not(.dropdown-menu) {
	padding: 0px 20px;
	margin: 0;
}


.BaseTable .ReactTable .rt-tbody,
.BaseTable .ReactTable .rt-table {
	overflow: visible;
}

.BaseTable .dropdown-menu {
	left: auto;
	right: 0;
}

.BaseTable .dropdown.btn-group,
.BaseTable .dropdown.btn-group button {
	border: none;
	background-color: transparent;
	box-shadow: none;
	font-size: 20px;
}

.BaseTable .ReactTable .rt-th,
.BaseTable .ReactTable .rt-td {
	white-space: normal;
	word-wrap: break-word;
	transition: none;
	overflow: visible;
}

.BaseTable .ReactTable .rt-th {
	display: flex;
    align-items: flex-end;
}

.BaseTable .ReactTable .rt-td {
	display: inline-flex;	
	flex-direction: column;
	justify-content: center;
}

.BaseTable .ReactTable .rt-tbody .rt-tr-group {
	border: none;
}

.BaseTable .ReactTable .rt-tbody .rt-tr-group:hover .rt-td,
.BaseTable .ReactTable .rt-tbody .rt-tr-group .-odd:hover .rt-td{
	background-color: var(--nin-color-ltgray3);
}


.BaseTable:not(.no-zebra) .rt-tbody .-even .rt-td {
	background-color: inherit;
}

.BaseTable:not(.no-zebra) .rt-tbody .-odd .rt-td {
	background-color: var(--nin-color-ltgray4);
}

.BaseTable .ReactTable .-pagination {
	box-shadow: none;
	padding: 0.5rem 0 0;
	border-top: none;
}

.BaseTable .ReactTable .rt-th .resizer {
	position: absolute;
	right: 0;
	top: 0;
	margin-right: -3px;
	height: 100%;
	width: 6px;
	cursor: col-resize;
	user-select: none;
	touch-action: none;
	z-index: 1000;
}

.BaseTable .ReactTable .rt-th .resizer.isResizing {
	opacity: 1;
}
.BaseTable .ReactTable .rt-th .-cursor-pointer {
	cursor: pointer
}

/* Chrome, Safari, Edge, Opera */
.BaseTable .ReactTable .-pagination .-pageJump input[type=number]::-webkit-outer-spin-button,
.BaseTable .ReactTable .-pagination .-pageJump input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.BaseTable .ReactTable .-pagination .-pageJump input[type=number] {
  -moz-appearance: textfield;
}

.BaseTable__no-items {
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.8);
	padding: 1rem;
	background: var(--nin-color-ltgray3);
	text-align: center;
	border-bottom: 1px solid #ddd;
}

.BaseTable__totals-cell {
	position: relative;
	background-color: var(--nin-color-ltgray3);
}

.BaseTable__totals-cell-label {
	position: absolute;
	width: 4rem;
	top: 0px;
	left: -4rem;
	background-color: inherit;
	padding: inherit;
}

.BaseTable__action-cell {
	width: 100%;
	text-align: right;
}

.BaseTable__sort-icon {
	display: inline;
	position: relative;
	bottom: -.125rem;
	width: .675rem;
	user-select:none;
	
}
