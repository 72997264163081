.Title {
	margin-bottom: .5rem;
}

.Title__container {
	justify-content: flex-start;
	flex-direction: row;
	align-items: center;

	padding-bottom: .5rem;
	border-bottom: 1px solid var(--nin-color-ltgray2);
}

.Title__container > * {
	margin-top: auto;
}

.Title__main-title {
	line-height: 2.5rem;
}

.Title__subtitle {
	line-height: 1;
	padding-bottom: .125rem;
	margin-top: auto;
	color: var(--nin-color-dkgray3);
	font-weight: 400;
}

.Title__status-container {
	margin-top: auto;
    padding-bottom: .25rem;
	flex-wrap: nowrap;
}

.Title__status-container > .status-mini {
    vertical-align: baseline;
    display: inline;
    white-space: nowrap;
}

.Title__buttons > * {
	margin-left: .375rem;
	margin-top: auto;
}
